// A chaque nouveau menu qui dispose de sous menu, penser à rajouter son name tel qu'il est écrit dans le fichier TopMenu affin de gérer l'apparition de son sous menu
export const adminNavigation = [
    {
        name: "Contenu",
        type: "link",
        path: "",
        child: true,
        submenu: [
            {
                path: "/evenement-liste",
                name: "Liste des évènements"
            },
            {
                path: "/contenu/creation-tags",
                name: "Créer des tags"
            },
            {
                path: "/contenu/creation-contenu",
                name: "Créer un contenu"
            },
        ]
    },
    {
        name: "Utilisateurs",
        type: "link",
        path: "",
        child: true,
        submenu: [
            {
                path: "/user/list",
                name: "Liste des utilisateurs"
            },
        ]
    },
    {
        name: "Divers",
        type: "link",
        path: "",
        child: true,
        submenu: [
            {
                path: "/newsletter-list",
                name: "Newsletter"
            },
            {
                path: "/adhesion-list",
                name: "Adhésions"
            }
        ]
    },
];