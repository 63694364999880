import React, { Suspense, useLayoutEffect, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, withRouter, Redirect, Switch } from 'react-router-dom';
import Loader from './components/sections/COMP/Loader.jsx'
import axios from 'axios'
import swal from "sweetalert2";
import TopMenu from './components/layouts/TopMenu.jsx'
import {adminNavigation} from "../src/adminNavigation.js"


const Login = React.lazy(() => import("./components/pages/Login/Login.jsx"));
const Error = React.lazy(() => import("./components/pages/Error"));

const Administration = React.lazy(() => import("./components/pages/Administration/Administration.jsx"));
const UserList = React.lazy(() => import("./components/pages/User/UserList.jsx"));
const NewsletterList = React.lazy(() => import("./components/pages/Newsletter/NewsletterList.jsx"));
const AdhesionList = React.lazy(() => import("./components/pages/Adhesion/AdhesionList.jsx"));
const Adhesion = React.lazy(() => import("./components/pages/Adhesion/Adhesion.jsx"));
const UserProfil = React.lazy(() => import("./components/pages/User/UserProfil.jsx"));
const TagsCreation = React.lazy(() => import("./components/pages/Contenu/TagsCreation.jsx"));
const ContenuCreation = React.lazy(() => import("./components/pages/Contenu/ContenuCreation.jsx"));
const ContenuCeationNext = React.lazy(() => import("./components/pages/Contenu/ContenuCeationNext.jsx"));
const ContenuList = React.lazy(() => import("./components/pages/Contenu/ContenuList.jsx"));
const ContenuPreview = React.lazy(() => import("./components/pages/Contenu/ContenuPreview.jsx"));


// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
})

function useAxiosInterceptor() {
  useEffect(() => {
    // Ajouter un intercepteur pour les requêtes sortantes
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        // Ajouter un header d'autorisation à chaque requête sortante
        const token = localStorage.getItem('jwt_token');
        config.headers.Authorization = "Bearer " + token;
        return config;
      }
    );

    // Ajouter un intercepteur pour les réponses entrantes
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        if (response.data.token) {
          localStorage.setItem('jwt_token', response.data.token);
        }
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          swal.fire({
            title: "Votre session a expiré",
            icon: 'error',
            showConfirmButton: false,
            allowOutsideClick: false,
          });
          setTimeout(() => {
            window.location.href = '/login';
          }, 2000);
        }
        return Promise.reject(error);
      }
    );

    // Nettoyer les intercepteurs lors du démontage du composant
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
}

function GuardAuth({ setUserInfo,children }) {
  const [loading,setLoading]=useState(true);
  const [isAuth,setIsAuth]=useState(false);
  
  const uninterceptedAxiosInstance = axios.create();

  useEffect(function () {
    (async function() {
        const response = await uninterceptedAxiosInstance.get(process.env.REACT_APP_API_URL+"api/auth/me",{headers: {'Authorization': 'Bearer ' + localStorage.getItem("jwt_token")}}
        ).then((response) => {
          setUserInfo(response.data)
          setIsAuth(true)
          setLoading(false)
        }).catch((error) => {
          setIsAuth(false)
          setLoading(false)
        })
    })()
  }, [])

  if(loading){
    return (
      <Loader></Loader>
    )
  }
  else{
    if(isAuth){
      return (
        <>
          {children}
        </>
     );
    }
    else{
      return(
        <Redirect to="/login" />
      )
    }
  }
}

function App() {
  useAxiosInterceptor();
  const [userInfo,setUserInfo]=useState(false);
  
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <Switch>
            <Route exact path="/login" component={Login} />
            <GuardAuth setUserInfo={setUserInfo}>
              <TopMenu menu={adminNavigation} userInfo={userInfo} />
              <Route exact path="/" component={Administration} />

              {/* Bloc Création de contenu */}
              <Route exact path="/contenu/creation-tags" component={TagsCreation} />
              <Route exact path="/contenu/creation-contenu" component={ContenuCreation} />
              <Route exact path="/contenu/creation-contenu-next/:id" component={ContenuCeationNext} />
              <Route exact path="/evenement-liste" component={ContenuList} />
              <Route exact path="/evenement-previsualisation/:id" component={ContenuPreview} />

              {/* Bloc user */}
              <Route exact path="/user/list" component={UserList} />
              <Route exact path="/user/profil/:id" component={UserProfil} />

              {/* Bloc newsletter */}
              <Route exact path="/newsletter-list" component={NewsletterList} />

              {/* Bloc adhésion */}
              <Route exact path="/adhesion-list" component={AdhesionList} />
              <Route exact path="/adhesion/:id" component={Adhesion} />
            </GuardAuth>
            <Route path="" component={Error} />
          </Switch>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
