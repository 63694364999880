import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

const TopMenu = (props) => {
    const [togleMenu,setTogleMenu]=useState("main-navigation") 
    const history = useHistory();
    const [isSubMenuVisible, setSubMenuVisible] = useState({
        user:false,
        Contenu:false,
        Utilisateurs:false,
    });

    const handleMouseEnter = (menu) => {
        setSubMenuVisible({...isSubMenuVisible,[menu]:true});
    };
    
    const handleMouseLeave = (menu) => {
        setSubMenuVisible({...isSubMenuVisible,[menu]:false});
    };

    const handleClickMenu = (menu) => {
        setSubMenuVisible({...isSubMenuVisible,[menu]:!isSubMenuVisible[menu]});
    };
    

    const togleMenuBurger = () =>{
        if(togleMenu==='main-navigation'){
            setTogleMenu('main-navigation active')
        }else{
            setTogleMenu('main-navigation')
        }
    }

    const disconnect = () =>{
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('auth_user')
        history.push("/login");
    }
    
    return (
        <header className={"header sticky"}>
            <div className="container-fluid custom-container">
                <div className="row">
                    <div className="col-12">
                        <div className="navigation">
                            <div className="logo">
                            <Link to="/">
                                <img src={process.env.PUBLIC_URL + "/assets/img/logo1.png"} className="image-fit" alt="logo" />
                            </Link>
                            </div>
                            <div className={togleMenu}>
                                <nav>
                                    <ul className="main-menu">
                                        {props.menu.map((item, i) => (
                                            <li key={i} className={`menu-item ${item.child ? 'menu-item-has-children' : ''} `}
                                                onMouseEnter={()=>handleMouseEnter(item.name)}
                                                onMouseLeave={()=>handleMouseLeave(item.name)}
                                                onClick={()=>handleClickMenu(item.name)}
                                            >
                                            {item.child ? 
                                                <Link to="#" className="text-custom-white">{item.name} <span className="arrow" /></Link> 
                                            : 
                                                <Link to={item.path} className="text-custom-white"> {item.name} </Link>}
                                            {item.child ?
                                                isSubMenuVisible[item.name] ? 
                                                    <ul className="sub-menu" role="menu">
                                                        {item.submenu.map((sub_item, i) => (
                                                            <li key={i} className='menu-item'>
                                                                <Link to={sub_item.path}> {sub_item.name}</Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                : false
                                            :
                                                null
                                            }
                                            </li>
                                        ))}
                                        {(props.userInfo) &&
                                        <li className='menu-item menu-item-has-children'
                                            onMouseEnter={()=>handleMouseEnter('user')}
                                            onMouseLeave={()=>handleMouseLeave('user')}
                                            onClick={()=>handleClickMenu('user')}
                                        >
                                            <Link to="#" className="text-custom-white">{props.userInfo.name} <span className="arrow" /></Link>
                                            {isSubMenuVisible.user ?
                                                <ul className="sub-menu" role="menu">
                                                    <li className='menu-item pointer' onClick={()=>disconnect()}>
                                                        Déconnexion
                                                    </li>
                                                </ul>
                                            : false}
                                        </li>
                                        }
                                    </ul>
                                </nav>
                            </div>
                            <div className="right-side-navigation">
                                <ul>
                                    <li className="hamburger-menu">
                                        <Link to="#" className="menu-btn" onClick={()=>togleMenuBurger()}>
                                            <span />
                                            <span />
                                            <span />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default TopMenu;